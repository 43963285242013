<template>
  <section class="section section-standalone" id="keuangan-daerah">
    <div class="container-fluid">
      <div class="my-3">
        <div class="c-dashboard-title d-flex justify-content-between pl-2">
          <h1 class="title-font text-size-title text-uppercase mb-0">
            Keuangan Daerah
          </h1>
          <div class="c-dashboard-title-option d-flex align-items-center">
            <select
              class="form-control form-control-sm mr-2 form-control-custom"
              v-model="dataTahun"
              v-on:change="tahunChange()"
            >
              <option :value="item" v-for="item in array_tahun" :key="item">
                {{ item }}
              </option>
            </select>
          </div>
        </div>
        <div class="grid-section">
          <div class="grid-section-item">
            <div
              class="bg-white rounded border h-100 border-radius-style font-museo"
            >
              <div class="card-dashboard-title border-bottom px-3 py-2">
                <h4
                  class="d-flex align-items-center justify-content-between mb-1 text-size-large titilium-font font-weight-normal head-with-border"
                >
                  Analisis Rencana, Realisasi dan Deviasi {{ dataTahun }}
                </h4>
              </div>
              <div class="card-dashboard-content">
                <div class="grid-keuangan p-3">
                  <div class="grid-keuangan-item">
                    <div class="bg-blue-new rounded p-3 h-100">
                      <img src="@/assets/img/icon/icon-keuangan-1.svg" alt="" />
                      <p
                        class="mb-0 secondary-font text-size-small mt-3 text-keuangan font-museo"
                      >
                        Realisasi Bulan
                      </p>
                      <p class="mb-0 secondary-font font-museo">
                        {{ getMonth(date) }}
                      </p>
                    </div>
                  </div>
                  <div class="grid-keuangan-item">
                    <div class="bg-green-new rounded p-3 h-100">
                      <img src="@/assets/img/icon/icon-keuangan-2.svg" alt="" />
                      <p
                        class="mb-0 secondary-font text-size-small mt-3 text-keuangan font-museo"
                      >
                        Realisasi Berdasarkan
                      </p>
                      <p class="mb-0 secondary-font font-museo">
                        SP2D
                      </p>
                    </div>
                  </div>
                  <div class="grid-keuangan-item">
                    <div class="grid-keuangan-second h-100">
                      <div class="grid-keuangan-second-item">
                        <div class="bg-green-new rounded p-3 h-100">
                          <div class="row">
                            <div class="col-md-6">
                              <p
                                class="mb-0 secondary-font text-list text-keuangan font-museo"
                              >
                                Rencana Terakhir
                              </p>
                            </div>
                            <div class="col-md-6">
                              <p
                                class="mb-0 secondary-font text-size-large text-keuangan
                                                    text-green-new font-weight-bold text-right font-museo"
                              >
                                {{
                                  perhitungan(rencana_terakhir, total_analisis)
                                }}%
                              </p>
                            </div>
                          </div>
                          <p
                            class="mb-0 secondary-font text-list text-right font-museo"
                          >
                            Rp. {{ formatPrice(rencana_terakhir) }}
                          </p>
                        </div>
                      </div>
                      <div class="grid-keuangan-second-item">
                        <div class="bg-orange-new rounded p-3 h-100">
                          <div class="row">
                            <div class="col-md-6">
                              <p
                                class="mb-0 secondary-font text-list text-keuangan font-museo"
                              >
                                Serapan Terakhir
                              </p>
                            </div>
                            <div class="col-md-6">
                              <p
                                class="mb-0 secondary-font text-size-large text-keuangan
                                                    text-orange-new font-weight-bold text-right font-museo"
                              >
                                {{
                                  perhitungan(serapan_terakhir, total_analisis)
                                }}%
                              </p>
                            </div>
                          </div>
                          <p
                            class="mb-0 secondary-font text-list text-right font-museo"
                          >
                            Rp. {{ formatPrice(serapan_terakhir) }}
                          </p>
                        </div>
                      </div>
                      <div class="grid-keuangan-second-item">
                        <div class="bg-blue-new rounded p-3 h-100">
                          <div class="row">
                            <div class="col-md-6">
                              <p
                                class="mb-0 secondary-font text-list text-keuangan font-museo"
                              >
                                Deviasi Terakhir
                              </p>
                            </div>
                            <div class="col-md-6">
                              <p
                                class="mb-0 secondary-font text-size-large text-keuangan
                                                    text-blue-new font-weight-bold text-right font-museo"
                              >
                                {{
                                  perhitungan(divisi_terakhir, total_analisis)
                                }}%
                              </p>
                            </div>
                          </div>
                          <p
                            class="mb-0 secondary-font text-list text-right font-museo"
                          >
                            Rp. {{ formatPrice(divisi_terakhir) }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="grid-section-item">
            <div class="bg-white rounded border h-100 border-radius-style">
              <div class="card-dashboard-title border-bottom px-3 py-2">
                <h4
                  class="d-flex align-items-center justify-content-between mb-1 text-size-large titilium-font font-weight-normal head-with-border"
                >
                  Kurva S
                </h4>
              </div>
              <div class="card-dashboard-content p-3">
                <canvas
                  id="keuangan-daerah-kurva"
                  width="300"
                  height="130"
                  :chart="keuanganChart"
                  class="d-none"
                ></canvas>
                <highcharts :options="keuanganChart2"></highcharts>
                <!-- <div class="row mx-auto mt-3" style="width: 450px;">
                                <div class="col-md-6">
                                    <ul class="no-list d-flex justify-content-start p-0 m-0">
                                        <li class="mx-1">
                                            <i style="color: #7CB5EC"
                                                class="fa fa-circle o-indicator-circle o-indicator-circle--success d-inline-block align-middle"></i>
                                            <p class="mb-0 d-inline-block text-size-mini">
                                                {{ kurvaLabel.rencana }}
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                                <div class="col-md-6">
                                    <ul class="no-list d-flex justify-content-start p-0 m-0">
                                        <li class="mx-1">
                                            <i style="color: #434348"
                                                class="fa fa-circle o-indicator-circle o-indicator-circle--normal d-inline-block align-middle"></i>
                                            <p class="mb-0 d-inline-block text-size-mini">
                                                {{ kurvaLabel.realisasi_sp2d_1 }}
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                                <div class="col-md-6">
                                    <ul class="no-list d-flex justify-content-start p-0 m-0">
                                        <li class="mx-1">
                                            <i style="color: #F15C80"
                                                class="fa fa-circle o-indicator-circle o-indicator-circle--danger d-inline-block align-middle"></i>
                                            <p class="mb-0 d-inline-block text-size-mini">
                                                {{ kurvaLabel.deviasi }}
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                                <div class="col-md-6">
                                    <ul class="no-list d-flex justify-content-start p-0 m-0">
                                        <li class="mx-1">
                                            <i style="color: #F7A35C"
                                                class="fa fa-circle o-indicator-circle o-indicator-circle--normal d-inline-block align-middle"></i>
                                            <p class="mb-0 d-inline-block text-size-mini">
                                                {{ kurvaLabel.realisasi_sp2d_2 }}
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                            </div> -->
              </div>
            </div>
          </div>
        </div>
        <div class="mt-3" style="margin-right: -15px;">
          <div class="bg-white rounded border h-100 border-radius-style">
            <div class="card-dashboard-title border-bottom px-3 py-2">
              <h4
                class="d-flex align-items-center justify-content-between mb-1 text-size-large titilium-font font-weight-normal head-with-border"
              >
                Top 10
              </h4>
            </div>
            <div class="card-dashboard-content p-3">
              <ul class="nav nav-tabs nav-content" id="myTab" role="tablist">
                <li class="nav-item" style="width:200px;">
                  <a
                    class="nav-link text-size-small px-0 mr-1 active"
                    id="deviasi-tertinggi-tab"
                    data-toggle="tab"
                    href="#deviasi-tertinggi"
                    role="tab"
                    aria-controls="deviasi-tertinggi"
                    aria-selected="true"
                    v-on:click="toggleTab(true)"
                  >
                    DEVIASI TERTINGGI
                  </a>
                </li>
                <li class="nav-item" style="width:200px;">
                  <a
                    class="nav-link text-size-small px-0 mr-1"
                    id="realisasi-terendah-tab"
                    data-toggle="tab"
                    href="#realisasi-terendah"
                    role="tab"
                    aria-controls="realisasi-skpd"
                    aria-selected="false"
                    v-on:click="toggleTab(false)"
                  >
                    REALISASI TERENDAH
                  </a>
                </li>
                <li class="nav-item" style="width:200px;">
                  <a
                    class="nav-link text-size-small px-0 mr-1"
                    id="anggaran-tab"
                    data-toggle="tab"
                    href="#anggaran"
                    role="tab"
                    aria-controls="anggaran"
                    aria-selected="false"
                    v-on:click="toggleTab(false)"
                  >
                    ANGGARAN BELUM TEREALISASI
                  </a>
                </li>
              </ul>
              <div class="tab-content tab-content-tabs">
                <div
                  class="tab-pane fade show active"
                  id="deviasi-tertinggi"
                  role="tabpanel"
                >
                  <div class="p-3">
                    <div
                      class="d-flex align-items-center mb-2"
                      v-for="(item, key) in deviasi_tertinggi"
                      :key="key"
                    >
                      <div class="flex-row mr-3">
                        <span
                          class="primary-font c-list-number text-body text-size-large"
                        >
                          {{ ++key }}
                        </span>
                      </div>
                      <div class="flex-row w-100">
                        <div
                          class="progress-lable d-flex align-items-center justify-content-between mb-1"
                        >
                          <p
                            class="text-size-normal mb-0 text-capitalize text-body"
                          >
                            {{ item.skpd }}
                          </p>
                          <div class="d-flex align-items-center">
                            <p
                              class="text-size-small mb-0 mr-2 text-body secondary-font"
                            >
                              Rp.
                              {{ formatPrice(item.total) }}
                            </p>
                            <span
                              class="badge bg-danger text-white font-weight-normal d-block p-1 pl-2 pr-2 rounded-lg"
                              >{{
                                parseFloat(item.persentase).toFixed(2) || 0
                              }}%</span
                            >
                          </div>
                        </div>
                        <div class="progress" style="height: 6px;">
                          <div
                            class="progress-bar bg-danger rounded-right"
                            role="progressbar"
                            :style="`width: ${item.persentase}%`"
                            aria-valuenow="30"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="realisasi-terendah"
                  role="tabpanel"
                >
                  <div class="p-3">
                    <div
                      class="d-flex align-items-center mb-2"
                      v-for="(item, key) in realisasi_terendah"
                      :key="key"
                    >
                      <div class="flex-row mr-3">
                        <span
                          class="primary-font c-list-number text-body text-size-large"
                        >
                          {{ ++key }}
                        </span>
                      </div>
                      <div class="flex-row w-100">
                        <div
                          class="progress-lable d-flex align-items-center justify-content-between mb-1"
                        >
                          <p
                            class="text-size-normal mb-0 text-capitalize text-body"
                          >
                            {{ item.skpd }}
                          </p>
                          <div class="d-flex align-items-center">
                            <p
                              class="text-size-small mb-0 mr-2 text-body secondary-font"
                            >
                              Rp.
                              {{ formatPrice(item.total) }}
                            </p>
                            <span
                              class="badge icon-orange-new text-white font-weight-normal d-block p-1 pl-2 pr-2 rounded-lg"
                              >{{
                                parseFloat(item.persentase).toFixed(2) || 0
                              }}%</span
                            >
                          </div>
                        </div>
                        <div class="progress" style="height: 6px;">
                          <div
                            class="progress-bar icon-orange-new rounded-right"
                            role="progressbar"
                            :style="`width: ${item.persentase}%`"
                            aria-valuenow="30"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" id="anggaran" role="tabpanel">
                  <div class="p-3">
                    <div
                      class="d-flex align-items-center mb-2"
                      v-for="(item, key) in belum_realisasi"
                      :key="key"
                    >
                      <div class="flex-row mr-3">
                        <span
                          class="primary-font c-list-number text-body text-size-large"
                        >
                          {{ ++key }}
                        </span>
                      </div>
                      <div class="flex-row w-100">
                        <div
                          class="progress-lable d-flex align-items-center justify-content-between mb-1"
                        >
                          <p
                            class="text-size-normal mb-0 text-capitalize text-body"
                          >
                            {{ item.skpd }}
                          </p>
                          <div class="d-flex align-items-center">
                            <p
                              class="text-size-small mb-0 mr-2 text-body secondary-font"
                            >
                              Rp.
                              {{ formatPrice(item.total) }}
                            </p>
                            <span
                              class="badge icon-blue-new text-white font-weight-normal d-block p-1 pl-2 pr-2 rounded-lg"
                              >{{
                                parseFloat(item.persentase).toFixed(2) || 0
                              }}%</span
                            >
                          </div>
                        </div>
                        <div class="progress" style="height: 6px;">
                          <div
                            class="progress-bar icon-blue-new rounded-right"
                            role="progressbar"
                            :style="`width: ${item.persentase}%`"
                            aria-valuenow="30"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="row sumber-data mt-3"
        v-if="sumber_data != null && Tab"
        v-tooltip.top-start="{
          content: `Pic : ${
            sumber_data.pic == null ? '-' : sumber_data.pic
          } <br>
                              Kontak : ${
                                sumber_data.kontak == null
                                  ? '-'
                                  : sumber_data.kontak
                              }`,
          show: sumberTooltip,
          trigger: 'manual'
        }"
        style="cursor:pointer"
        v-on:mouseover="changeSumberTooltip"
        v-on:click="changeSumberTooltip"
      >
        <div class="col-md-12 ">
          <p class="text-size-normal ">
            &nbsp; Sumber Data : {{ sumber_data.sumber }} <br />
            &nbsp; Pembaharuan Terakhir :
            {{ sumber_data.updated_at == null ? '-' : sumber_data.updated_at }}
          </p>
        </div>
      </div>
      <div
        class="row sumber-data mt-3"
        v-if="sumber_data_realisasi != null && !Tab"
        v-tooltip.top-start="{
          content: `Pic : ${
            sumber_data_realisasi.pic == null ? '-' : sumber_data_realisasi.pic
          } <br>
                              Kontak : ${
                                sumber_data_realisasi.kontak == null
                                  ? '-'
                                  : sumber_data_realisasi.kontak
                              }`,
          show: sumberTooltip,
          trigger: 'manual'
        }"
        style="cursor:pointer"
        v-on:mouseover="changeSumberTooltip"
        v-on:click="changeSumberTooltip"
      >
        <div class="col-md-12 ">
          <p class="text-size-normal ">
            &nbsp; Sumber Data : {{ sumber_data_realisasi.sumber }} <br />
            &nbsp; Pembaharuan Terakhir :
            {{
              sumber_data_realisasi.updated_at == null
                ? '-'
                : sumber_data_realisasi.updated_at
            }}
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
var _that;
var myChart;
var tahun = new Date(window.date_now);
export default {
  metaInfo() {
    return {
      title: 'Keuangan Daerah'
    };
  },
  data() {
    return {
      Tab: true,
      sumberTooltip: false,
      sumber_data: null,
      sumber_data_realisasi: null,
      keuanganChart2: {
        exporting: {
          filename: `Target Pensertipikatan Tanah`,
          sourceWidth: 1500,
          sourceHeight: 800,
          scale: 2
        },
        credits: {
          enabled: false
        },
        chart: {
          type: 'spline',
          height: 300
          // inverted: true
        },
        plotOptions: {
          spline: {
            marker: {
              enable: false
            }
          }
        },
        legend: {
          itemDistance: 20
        },
        xAxis: {
          categories: [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'Mei',
            'Jun',
            'Jul',
            'Ags',
            'Sep',
            'Okt',
            'Nov',
            'Des'
          ],
          title: {
            text: null
          },
          tickInterval: 1
        },
        yAxis: {
          min: 0,
          title: {
            text: '',
            align: 'high'
          },
          labels: {
            overflow: 'justify'
          }
        },
        series: [],
        title: {
          text: null
        },
        tooltip: {
          formatter: function() {
            return (
              '<b> ' +
              this.series.name +
              ' : Bulan ' +
              this.x +
              ' </b> <br>' +
              _that.satuanNominal(this.y, 2) +
              ' (' +
              _that.formatPrice((this.y / _that.rencana_terakhir) * 100) +
              ' %)'
            );
          }
        }
      },
      keuanganChart: {
        type: 'line',
        data: {
          labels: [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'Mei',
            'Jun',
            'Jul',
            'Ags',
            'Sep',
            'Okt',
            'Nov',
            'Des'
          ],
          datasets: [
            {
              fill: false,
              borderWidth: 4,
              data: [80, 60, 70, 80, 50, 100, 50, 120, 130, 140, 50, 160, 170],
              lineTension: 0,
              backgroundColor: '#7CB5EC',
              borderColor: '#7CB5EC'
            },
            {
              fill: false,
              borderWidth: 4,
              data: [55, 65, 75, 85, 12, 105, 115, 12, 135, 145, 23, 111, 175],
              lineTension: 0,
              backgroundColor: '#F15C80',
              borderColor: '#F15C80'
            },
            {
              fill: false,
              borderWidth: 4,
              data: [45, 55, 65, 75, 12, 95, 12, 115, 125, 135, 145, 155, 165],
              lineTension: 0,
              backgroundColor: '#434348',
              borderColor: '#434348'
            },
            {
              fill: false,
              borderWidth: 4,
              data: [85, 65, 55, 45, 75, 15, 125, 115, 155, 145, 165, 155, 155],
              lineTension: 0,
              backgroundColor: '#F7A35C',
              borderColor: '#F7A35C'
            }
          ]
        },
        options: {
          bezierCurve: false,
          legend: {
            display: false
          },
          elements: {
            point: {
              radius: 0
            }
          },
          scales: {
            yAxes: [
              {
                gridLines: {
                  drawBorder: false,
                  display: false
                },
                ticks: {
                  callback: function(label) {
                    return _that.commarize(label);
                  }
                }
              }
            ]
          },
          tooltips: {
            mode: 'index',
            intersect: false,
            callbacks: {
              label: function(label) {
                return 'Rp. ' + _that.formatPrice(label.yLabel);
              }
            }
          },
          hover: {
            mode: 'nearest',
            intersect: true
          }
        }
      },
      deviasi_tertinggi: [],
      realisasi_terendah: [],
      belum_realisasi: [],
      deviasi_tertinggi_total: 0,
      realisasi_terendah_total: 0,
      belum_realisasi_total: 0,
      rencana_terakhir: 0,
      serapan_terakhir: 0,
      divisi_terakhir: 0,
      total_analisis: 0,
      date: 0,
      array_tahun: window.date_years,
      dataTahun: tahun.getFullYear(),
      tahun: tahun.getFullYear(),
      kurvaLabel: {
        deviasi: '',
        rencana: '',
        realisasi_sp2d_1: '',
        realisasi_sp2d_2: ''
      }
    };
  },
  mounted() {
    this.loadChart();
    _that = this;
  },
  methods: {
    changeSumberTooltip() {
      this.sumberTooltip = true;
      setTimeout(() => {
        this.sumberTooltip = false;
      }, 3000);
    },
    loadChart() {
      window.axios
        .post(window.base_api + 'page/keuanganDaerah', {
          tahun: this.dataTahun
        })
        .then(response => {
          this.sumber_data = response.data.sumber_data;
          this.sumber_data_realisasi = response.data.sumber_data_realisasi;

          if (response.data.status !== 'errors') {
            this.deviasi_tertinggi = response.data.top_10.deviasi_tertinggi;
            this.realisasi_terendah = response.data.top_10.realisasi_terendah;
            this.belum_realisasi = response.data.top_10.belum_realisasi;

            if (this.belum_realisasi.length > 0) {
              this.belum_realisasi = this.belum_realisasi.sort(this.compare);
            }

            this.keuanganChart2.series = response.data.kurva_full;
            this.keuanganChart.data.datasets[0].data =
              response.data.kurva.rencana.data;
            this.keuanganChart.data.datasets[1].data =
              response.data.kurva.deviasi.data;
            this.keuanganChart.data.datasets[2].data =
              response.data.kurva.realisasi_sp2d_1.data;
            this.keuanganChart.data.datasets[3].data =
              response.data.kurva.realisasi_sp2d_2.data;

            this.kurvaLabel.deviasi = response.data.kurva.deviasi.name;
            this.kurvaLabel.rencana = response.data.kurva.rencana.name;
            this.kurvaLabel.realisasi_sp2d_1 =
              response.data.kurva.realisasi_sp2d_1.name;
            this.kurvaLabel.realisasi_sp2d_2 =
              response.data.kurva.realisasi_sp2d_2.name;

            const key = 11;
            var itemNull = response.data.kurva.rencana.data.filter(d => {
              return d == null;
            });

            itemNull = response.data.kurva.realisasi_sp2d_1.data.filter(d => {
              return d == null;
            });
            var rmKey = itemNull.length > 0 ? itemNull.length : 0;
            this.rencana_terakhir =
              response.data.kurva.rencana.data[key - rmKey];
            this.serapan_terakhir =
              response.data.kurva.realisasi_sp2d_1.data[key - rmKey];
            itemNull = response.data.kurva.deviasi.data.filter(d => {
              return d == null;
            });
            rmKey = itemNull.length > 0 ? itemNull.length : 0;
            this.divisi_terakhir =
              response.data.kurva.deviasi.data[key - rmKey];

            itemNull = response.data.kurva.realisasi_sp2d_1.data.filter(d => {
              return d == null;
            });
            rmKey = itemNull.length > 0 ? itemNull.length : 0;
            this.date = key - rmKey;

            itemNull = response.data.kurva.rencana.data.filter(d => {
              return d == null;
            });
            rmKey = itemNull.length > 0 ? itemNull.length : 0;
            this.total_analisis = response.data.kurva.rencana.data[key - rmKey];
          }

          this.setChart();
        });
    },
    setChart() {
      var ctx = document
        .getElementById('keuangan-daerah-kurva')
        .getContext('2d');
      myChart = new window.Chart(ctx, {
        type: this.keuanganChart.type,
        data: this.keuanganChart.data,
        options: this.keuanganChart.options
      });
    },
    formatPrice(value) {
      let val = value / 1 > 0 ? (value / 1).toFixed(2).replace('.', ',') : 0;
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    },
    commarize(num) {
      var si = [
        { value: 1, symbol: '' },
        { value: 1e3, symbol: 'k' },
        { value: 1e6, symbol: 'M' },
        { value: 1e9, symbol: 'G' },
        { value: 1e12, symbol: 'T' },
        { value: 1e15, symbol: 'P' },
        { value: 1e18, symbol: 'E' }
      ];
      var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
      var i;
      for (i = si.length - 1; i > 0; i--) {
        if (num >= si[i].value) {
          break;
        }
      }
      return (num / si[i].value).toFixed(2).replace(rx, '$1') + si[i].symbol;
    },
    getMonth(date) {
      const monthNames = window.date_month;

      return monthNames[date];
    },
    sumArray(array) {
      return array.reduce((a, b) => a + b, 0);
    },
    toggleTab(status) {
      this.Tab = status;
    },
    compare(a, b) {
      a = a.persentase;
      b = b.persentase;

      let comparison = 0;
      if (a > b) {
        comparison = 1;
      } else if (a < b) {
        comparison = -1;
      }
      return comparison * -1;
    },
    perhitungan(nilai, pembagi) {
      let hasil = (nilai / pembagi) * 100;
      return hasil > 0 ? hasil.toFixed(2) : 0;
    },
    tahunChange() {
      myChart.destroy();
      this.loadChart();
    },
    satuanNominal(num, digits) {
      var min = false;
      var nilai;
      if (num < 0) {
        min = true;
        num = num * -1;
      }
      var si = [
        { value: 1, symbol: '' },
        { value: 1e3, symbol: ' Ribu' },
        { value: 1e6, symbol: ' Juta' },
        { value: 1e9, symbol: ' M' },
        { value: 1e12, symbol: ' T' },
        { value: 1e15, symbol: ' Kuadriliun' },
        { value: 1e18, symbol: ' Kuantiliun' }
      ];
      var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
      var i;
      for (i = si.length - 1; i > 0; i--) {
        if (num >= si[i].value) {
          break;
        }
      }
      nilai = num / si[i].value;
      if (min == true) {
        nilai = nilai * -1;
      }
      return nilai.toFixed(digits).replace(rx, '$1') + si[i].symbol;
      //return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
    }
  }
};
</script>

<style></style>
